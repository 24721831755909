<template>
    <section>
        <modal ref="modalCalificarEntrega" titulo="Califique esta entrega" :desactivar-guardar="false" no-aceptar tamano="modal-lg" adicional="Enviar" @adicional="calificar()">
            <div class="row mx-0">
                <div class="col-auto" />
                <div class="col">
                    <div class="row mx-0">
                        <div class="col-auto px-0">
                            <img :src="_.get(data, 'usuario_foto')" class="obj-cover rounded-circle" width="55" height="55" />
                        </div>
                        <div class="col">
                            <div class="row mx-0">
                                <p class="col tres-puntos tres-puntos"> {{ _.get(data, 'nombre') }} </p>
                                <p class="col tres-puntos tres-puntos"> {{ formatearFecha(_.get(data, 'user_fecha')) }} </p>
                                <div class="col-auto px-0">
                                    <el-tooltip placement="bottom" content="Respuestas" effect="light">
                                        <i class="icon-message f-20 text-general cr-pointer" @click="verRespuesta(data)" />
                                    </el-tooltip>
                                </div>
                                <p class="col-12 tres-puntos text-gris">
                                    {{ _.get(data, 'cargo') }}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="row mx-0 j-center">
                        <div class="col-10 text-gris f-18 text-center my-3">
                            De 1 a 5 siendo 5 la nota mayor califique el contenido de la entrega realizada.
                        </div>
                        <div class="col-10 my-5 text-center">
                            <el-radio-group v-model="radio">
                                <el-radio class="radio-absolute" label="1" />
                                <el-radio class="radio-absolute" label="2" />
                                <el-radio class="radio-absolute" label="3" />
                                <el-radio class="radio-absolute" label="4" />
                                <el-radio class="radio-absolute" label="5" />
                            </el-radio-group>
                        </div>
                        <div class="col-10">
                            <label class="text-gris f-15 pl-3"> ¿Tiene algún comentario sobre esta entrega? </label>
                            <el-input v-model="texto" class="w-100 my-3 area-radius" type="textarea" :rows="6" placeholder="Texto..." show-word-limit maxlength="500" />
                        </div>
                    </div>
                </div>
                <div class="col-auto" />
            </div>
        </modal>
        <!-- Partial -->
        <modal-comentario-entrega ref="VerRespuestas" :data="info" />
    </section>
</template>

<script>
import { entregasHechas } from '~/services/cursos/entregas_hechas';
export default {
    components: {
        modalComentarioEntrega: () => import('./modalComentarioEntrega')
    },
    props: {
        data: {
            type: Object,
            default: () => ({})
        }
    },
    data(){
        return {
            radio: '5',
            texto: '',
            info : {}
        }
    },
    methods: {
        toggle(){
            this.radio = '5'
            this.texto = ''

            this.$refs.modalCalificarEntrega.toggle();
        },
        verRespuesta(data){
            this.$refs.modalCalificarEntrega.toggle();
            this.$refs.VerRespuestas.toggle();
            this.info = data
        },
        async calificar(){
            try {
                const obj = {
                    nota: this.radio,
                    comentario: this.texto
                }
                const { data } = await entregasHechas.calificar(this.data.id_hecha, obj)
                if (data.data){
                    this.notificacion('', 'Entrega calificada correctamente', 'success')
                    this.$emit('updated')
                    this.toggle()
                }                
            } catch (e){
                this.error_catch(e)
            }
        }
    }
}
</script>

<style lang="scss" scoped>
</style>